







































































import { Component, Prop, Watch } from 'vue-property-decorator';
import ErrorMessageHandlerMixin from '../misc/ErrorMessageHandler.mixin';
import { mixins } from 'vue-class-component';
import { required, numeric } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { namespace } from 'vuex-class';
import Company from '@/models/Company';
import { applicationStoreGetter } from '@/store/application.store';
import Taxi from '@/models/Taxi';
import { taxiStoreActions } from '@/store/taxi.store';

const TaxiStore = namespace('taxi');
const ApplicationStore = namespace('application');

@Component({
    mixins: [validationMixin],
    validations: {
        taxiCopy: {
            taxiNo: {
                required
            },
            plate: {
                required
            },
            seats: {
                required,
                numeric
            },
            type: {
                required
            }
        }
    }
})
export default class EditTaxiComponent extends mixins(ErrorMessageHandlerMixin) {

    @Prop({ default: () => new Taxi() })
    public taxi!: Taxi;

    @ApplicationStore.Getter(applicationStoreGetter.SELECTED_COMPANY)
    public selectedCompany!: Company;

    @TaxiStore.Action(taxiStoreActions.CREATE)
    public createTaxiAction!: (payload: { taxi: Taxi, company: Company }) => Promise<Taxi>;

    @TaxiStore.Action(taxiStoreActions.UPDATE)
    public updateTaxiAction!: (payload: { taxi: Taxi }) => Promise<Taxi>;

    public taxiCopy: Taxi = new Taxi();
    public validData = false;
    public isLoading: boolean = false;

    @Watch('taxi', { immediate: true })
    public taxiChanged() {
        if (this.taxi) {
            this.taxiCopy = this.taxi.copy() as Taxi;
            this.validData = !this.$v!.$invalid;
        }
    }

    public async saveData() {
        if (this.validData) {
            try {
                this.isLoading = true;
                if (!this.taxi.id) {
                    await this.createTaxiAction({
                        taxi: this.taxiCopy,
                        company: this.selectedCompany
                    });
                } else {
                    await this.updateTaxiAction({ taxi: this.taxiCopy });
                }
                this.dismiss(true);
            } catch (err) {
                console.log(err);
            } finally {
                this.isLoading = false;
            }
        }
    }

    public dismiss(reloadData: boolean = false) {
        if (reloadData) {
            this.taxiCopy = new Taxi();
        }

        this.$v.$reset();
        this.$emit('closeDialog', reloadData);
    }

    public checkForm(type: string) {
        this.validData = !this.$v!.$invalid;
        this.triggerValidation(type);
    }
}

